<template>
  <table>
    <tr>
      <td style="width:15%;min-width:100px;" >
        <select class="normalInput" ref="site" id="select">
          <option value = "" selected>사이트 선택</option>
          <option value = "CAROUSELL">CAROUSELL</option>
          <option value = "VINTED">VINTED</option>
          <option value = "SHOPEE">SHOPEE</option>
          <option value = "MERCI">MERCI</option>
        </select>
      </td>
      <td style="width:100%;" >
        <input class="normalInput" v-on:keyup.enter="calcPrice(3)" ref="url" id="url" placeholder="구매 원하시는 상품의 주소(URL)" type="url">
      </td>
      <td style="min-width:100px;" >
        <Button v-on:click="calcPrice(3)" style="width:100%;" label="구매대행비" raised size="small" />
      </td>
    </tr>
  </table>
  <div class="container">
    <table class="table" ref="table" style="display:none;">
      <tr>
        <td ><img ref="result1" style="height:150px;" class="padding-left" alt="geek9.kr"></td>
        <td class="td">
          <div class="padding" ref="result2"/> 
          <div class="padding" ref="result3"/> 
          <div class="padding" ref="result4"/> 
          <div class="padding" ref="result5"/> 
          <div class="padding" ref="result6"/>
          <div class="padding"><a target="_blank" ref="link">링크</a></div>
          <div>
            <Button @click="order()" label="주문하기" raised size="small" />
          </div>
        </td>
      </tr>
    </table>
  </div>
  <Modal v-model='isShow' :close='closeModal' style="z-index: 99999999999;">
    <div class="modal">
      <div style="margin-top:20px;margin-bottom:20px;">
        <p>자동견적을 낼 수 없는 상품입니다.</p>
        <p>해외판매가격을 직접 입력해주시면</p>
        <p>예상견적(배송비별도)을 확인하실 수 있습니다.</p>
        <p>견적을 확인하시고 대행신청을 해주시면 구매대행을 진행하실 수 있습니다.</p>
      </div>
      <table>
        <tr>
          <td style="min-width:100px;">
            <select class="normalInput" ref="modalCountry" >
              <option value = "" selected>국가선택</option>
              <option value = "hk">홍콩</option>
              <option value = "sg">싱가포르</option>
            </select>
          </td>
          <td style="width:100%;">
            <input class="normalInput" v-on:keyup.enter="calcPrice(4)" ref="modalSellPrice" placeholder="해외가격" type="number" style="width:100%;">
          </td>
          <td style="min-width:50px;" >
            <Button  v-on:click="calcPrice(4)" label="조회" raised size="small" />
          </td>
          <td style="min-width:50px;">
            <Button @click="closeModal()" label="닫기" raised size="small" />
          </td>
        </tr>
      </table>
    </div>
  </Modal>
</template>

<script>

import router from '../../router'
import { ref } from 'vue'

export default {
  setup() {
    const isShow = ref(false)
    async function showModal () {
      isShow.value = true
    }
    function closeModal () {
      isShow.value = false
    }
    return {
      closeModal, showModal, isShow
    }
  },
  data() { 
    return {
       url:'',
       name:'',
       sellPrice:'',
       country:'',
       imgUrl:'',
       wonPrice:''
    }; 
  },
  methods: {
    async order() {
      router.push({ name: "order", params: { 
        url: this.url, 
        name: this.api.emojiRemover(this.name), 
        sellPrice:this.sellPrice, 
        country:this.country, 
        imgUrl:this.imgUrl, 
        wonPrice:this.wonPrice } })
    },
    async calcPrice(type) {
      if(type === 3) {
        let site = this.$refs.site.value; 
        let url = this.$refs.url.value;
        if(site === '' || url == '' ) {
          alert('입력하지 않은 값이 있습니다')
        } else if(url.indexOf('vinted') === -1 && url.indexOf('carousell') === -1 && url.indexOf('merci') === -1 && url.indexOf('shopee') === -1) { 
          this.$notify('vinted / caousell / shopee / merci 사이트만 조회 가능합니다\n(추후 가능한 사이트 추가 예정)')
          alert('vinted / caousell / shopee / merci 사이트만 조회 가능합니다\n(추후 가능한 사이트 추가 예정)')
        } else {
          const site = this.$refs.site.value
          if(site === 'CAROUSELL' || site === 'SHOPEE') this.showModal()
          else {
            this.emitter.emit('dialog', {flag:true, title:'loading'})
            url = this.api.shortenUrl(url)
            
            const param = {'type': site, 'url': url}
            
            await this.api.axiosGql(site, param) 
            .then((res) => { 
              if(res.data.error !== undefined) {
                console.log(res.data)
                alert(res.data.error)
              } else if(res.status === 201 ) {
                this.showModal()
              } else {
                this.wonPrice = res.data.sellPrice;
                this.imgUrl = res.data.imgUrl;
                this.name = res.data.name
                this.sellPrice = res.data.pagePrice
                const deliveryFee = res.data.deliveryFee
                this.country = res.data.country
                this.url = res.data.url

                let unit
                if(this.country ==='홍콩') unit = ' hkd'
                else if(this.country ==='싱가포르') unit = ' sgd'
                else if(this.country ==='영국') unit = ' 파운드'
                // else if(this.country === '중국') unit = ' 위안'
                // else if(this.country ==='일본') unit = ' 엔'
                // else if(this.country ==='호주') unit = ' aud'
                // else if(this.country ==='뉴질랜드') unit = ' nzd'
                // else if(this.country ==='베트남') unit = ' vnd'
                else unit = ' euro'
                
                this.$refs.table.style.display = '';
                this.$refs.result1.src = this.imgUrl;
                this.$refs.link.href = this.url;

                this.$refs.result2.innerHTML = '상품명 : ' + this.name
                this.$refs.result3.innerHTML = '현지구매가 : ' + this.sellPrice + unit
                this.$refs.result4.innerHTML = '현지배송비 : ' + deliveryFee + unit
                this.$refs.result5.innerHTML = '대행국가 : ' + this.country
                this.$refs.result6.innerHTML = '대행비 : ' + parseInt(this.wonPrice).toLocaleString() + '원 <label style="color:red">(수수료 포함, 배송비 별도)</label>'
                
              }
            }) 
            .catch((error) => { 
              alert(error)
            }) 
            .finally(() => { 
              this.api.axiosGql('INSERT_STAT_KEYWORD', {type:'INSERT_STAT_KEYWORD', searchType:'0', keyword:url, opt:site, mail:this.api.getCookies('mail')})
              this.emitter.emit('dialog', {flag:false, title:'loading'})
            })
          }
        }
      } else if(type === 4) {
        const country  = this.$refs.modalCountry.value
        const price = this.$refs.modalSellPrice.value

        if(price === '') alert('해외가격을 입력해주세요')
        else if(country === '') alert('국가를 선택해주세요')
        else if(country === 'hk') {
          const param = {type: 'currency', country: '홍콩'}
          await this.api.axiosGql('currency', param) 
          .then(async (res) => { 
            const currency = res.data
            if(currency === undefined || currency === null || currency === 'null' || currency === '' ) {
              alert('다시 시도해주세요')
            } else {
              let wonPrice = (parseFloat(price) + 100) * parseFloat(currency)
              let fee = wonPrice * 0.1
              let sellPrice = 0
              if(fee < 8000) sellPrice = wonPrice + 8000
              else sellPrice = wonPrice + fee
              sellPrice = Math.ceil(sellPrice / 100) * 100
              alert("예상가격은 " + sellPrice.toLocaleString() + '입니다(수수료포함,배송비별도)')
            }
          })
        } else if(country === 'sg') {
          const param = {type: 'currency', country: '싱가포르'}
          await this.api.axiosGql('currency', param) 
          .then(async (res) => { 
            const currency = res.data
            if(currency === undefined || currency === null || currency === 'null' || currency === '' ) {
              alert('다시 시도해주세요')
            } else {
              let wonPrice = (parseFloat(price) + 10) * parseFloat(currency)
              let fee = wonPrice * 0.1
              let sellPrice = 0
              if(fee < 8000) sellPrice = wonPrice + 8000
              else sellPrice = wonPrice + fee
              sellPrice = Math.ceil(sellPrice / 100) * 100
              alert("예상가격은 " + sellPrice.toLocaleString() + '입니다(수수료포함,배송비별도)')
            }
          })
        }
      }
    },
  }
}
</script>

<style scoped>
.table {
  width: 100%;
  margin-top: 10px;
}
.padding-left {
  padding-left:10px;
}
.td {
  width:100%; 
  text-align:right;
}
td {
  padding: 0px;
}
.padding {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
#url {
  width: 100%;
}
#select {
  width: 100%;
  min-width: 100px;
  text-align: center;
}
input {
  width: auto;
}
</style>
