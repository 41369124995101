<template>
  <div>
    <Splitter style="border:none;" >
      <SplitterPanel class="flex items-center justify-center" :minSize="50" >
        <div class="flex flex-wrap gap-4 p-fluid" style="padding-right:20px;">
          <Tag severity="info" value="구매대행비"/>
          <Select class="input-margin" v-model="country" :options="countries" optionLabel="name" placeholder="Select a Country" style="width:100%;" @change="change" />
          <FloatLabel class="flex justify-center input-margin" >
            <InputNumber id="fPrice"  v-model="fPrice" :min="0" size="small" :minFractionDigits="2" :currency="currency"  mode="currency" v-tooltip.right="'해외 사이트에서 판매하는 가격'" />
            <label for="fPrice" >해외가격</label>
          </FloatLabel>
          <FloatLabel class="flex justify-center input-margin" >
            <InputNumber id="dPrice" v-model="dPrice" :min="0" size="small" :minFractionDigits="2" :currency="currency"  mode="currency" v-tooltip.right="'해외 사이트에서 부과되는 현지 배송비'" />
            <label for="dPrice" >해외배송비</label>
          </FloatLabel>
          <FloatLabel class="flex justify-center input-margin" >
            <InputNumber id="ePrice" v-model="ePrice" :min="0" size="small" :minFractionDigits="2" :currency="currency"  mode="currency" v-tooltip.right="'해외 사이트에서 판매하는 가격과 현지 배송비 이외의 추가되는 비용'"/>
            <label for="ePrice" >기타비용</label>
          </FloatLabel>
          <br><br>
          <div class="tooltip e_values" v-tooltip.right="'수수료를 포함한 구매대행 예상 가격입니다. 환율에 따라 가격이 변동됩니다.'">예상대행비 <div class="e_price" id="e_val1"></div>
          </div>
          <span class="status_cnt">
            <Button @click="calcPrice(0)" label="구매대행비" raised size="small" />
          </span>
        </div>
      </SplitterPanel>
      <SplitterPanel class="flex items-center justify-center" :minSize="50" > 
        <div class="flex flex-wrap gap-4 p-fluid" style="padding-left:20px;">
          <Tag severity="info" value="배송대행비"/>
          <FloatLabel class="flex justify-center input-margin" >
            <InputNumber id="horizon" v-model="horizon" :min="0" size="small" :minFractionDigits="2" v-tooltip.left="'한국으로 보낼 상품의 가로 길이(mm)'"/>
            <label for="horizon" >가로(mm)</label>
          </FloatLabel>
          <FloatLabel class="flex justify-center input-margin" >
            <InputNumber id="vertical" v-model="vertical" :min="0" size="small" :minFractionDigits="2" v-tooltip.left="'한국으로 보낼 상품의 세로 길이(mm)'"/>
            <label for="vertical" >세로(mm)</label>
          </FloatLabel>
          <FloatLabel class="flex justify-center input-margin" >
            <InputNumber id="height" v-model="height" :min="0" size="small" :minFractionDigits="2" v-tooltip.left="'한국으로 보낼 상품의 높이 길이(mm)'"/>
            <label for="height" >높이(mm)</label>
          </FloatLabel>
          <FloatLabel class="flex justify-center input-margin" >
            <InputNumber id="weight" v-model="weight" :min="0" size="small" :minFractionDigits="2" v-tooltip.left="'한국으로 보낼 상품의 무게(g)'"/>
            <label for="weight" >무게(g)</label>
          </FloatLabel>
        <br>
        <br>
        <div class="tooltip e_values" v-tooltip.top="'해외에서 한국으로 보내는 배송비입니다. 포장 완료된 상태의 무게와 부피에 따라 가격이 결정됩니다.'">
          예상배송비 
          <div class="e_price" id="e_val2"></div>
        </div>
        <span class="status_cnt">
          <Button @click="calcPrice(1)" label="배송대행비" raised size="small" />
        </span>
      </div>
      </SplitterPanel>
    </Splitter>
  </div> 
</template>
<script>
import { ref } from 'vue'
export default {
  setup() {
    const country = ref()
    const countries = ref([
      { name: '홍콩(HKD)', code: '홍콩환율', currency:'HKD' },
      { name: '프랑스(EUR)', code: '유로환율', currency:'EUR' },
      { name: '영국(GBP)', code: '영국환율', currency:'GBP' },
      { name: '스페인(EUR)', code: '유로환율', currency:'EUR' },
      { name: '독일(EUR)', code: '유로환율', currency:'EUR' },
      { name: '이탈리아(EUR)', code: '유로환율', currency:'EUR' },
      { name: '일본(JPY)', code: '일본환율', currency:'JPY' },
      { name: '중국(CNY)', code: '중국환율', currency:'CNY' },
      { name: '미국(USD)', code: '미국환율', currency:'USD' },
      { name: '캐나다(CAD)', code: '캐나다환율', currency:'CAD' },
      { name: '싱가포르(SGD)', code: '싱가포르환율', currency:'SGD' },
      { name: '네덜란드(EUR)', code: '유로환율', currency:'EUR' },
      { name: '호주(AUD)', code: '호주환율', currency:'AUD' },
      { name: '뉴질랜드(NZD)', code: '뉴질랜드환율', currency:'NZD' },
      { name: '오스트리아(EUR)', code: '오스트리아환율', currency:'EUR' }
    ])
    return {
      countries, country
    }
  },
  data() {
    return {
      currency:"HKD",
      horizon:null,
      vertical:null,
      height:null,
      weight:null,
      fPrice:null,
      dPrice:null,
      ePrice:null,
    }
  },
  methods: {
    change() {
      this.currency = this.country.currency 
    },
    async calcPrice(type) {
      if(type === 0) {
        const countryCode = this.country.code 
        console.log('this.fPrice')
        console.log(this.fPrice === 0 ? true : false)
        if(countryCode === '' || this.fPrice === '' || this.dPrice === '' || this.ePrice === '' || countryCode === undefined || this.fPrice === null || this.dPrice === null || this.ePrice === null) {
          alert('입력하지 않은 값이 있습니다')
        } else {
          this.emitter.emit('dialog', {flag:true, title:'loading'})
          const param = {type: 'calcBuy', country: countryCode, sellPrice: this.fPrice, delivery: this.dPrice, etc: this.ePrice}
          await this.api.axiosGql('calcBuy', param) 
          .then((res) => {
            let won = res.data.result
            won = parseInt(won.toString()).toLocaleString()
            document.getElementById("e_val1").innerHTML = won + "원"
          }) 
          .catch((error) => { 
            console.log(error)
          }) 
          .finally(() => { 
            this.emitter.emit('dialog', {flag:false, title:'loading'})
          })
        }
      } else if(type === 1) {
        if(this.horizon === '' || this.vertical == '' || this.height == '' || this.weight == '' || this.horizon === null || this.vertical == null || this.height == null || this.weight == null ) {
          alert('입력하지 않은 값이 있습니다');
        } else {
          this.emitter.emit('dialog', {flag:true, title:'loading'})
          const param = {'type': 'calcDelivery', 'horizon': this.horizon/10, 'vertical': this.vertical/10, 'height': this.height/10, 'weight': this.weight/1000};
          await this.api.axiosGql('calcDelivery', param) 
          .then((res) => { 
            let won = res.data.result
            won = parseInt(won.toString()).toLocaleString()
            document.getElementById("e_val2").innerHTML =  won + "원";
          }) 
          .catch((err) => { console.log(err); }) 
          .finally(() => { 
            this.emitter.emit('dialog', {flag:false, title:'loading'})
          });
        }
      }
    },
    changeCountry(event) {
      var currency = ''
      switch(event.target.value) {
        case '유로환율':
          currency = 'EUR'
          break
        case '홍콩환율':
          currency = 'HKD'
          break
        case '영국환율':
          currency = 'GBP'
          break          
        case '일본환율':
          currency = 'JPY'
          break
        case '중국환율':
          currency = 'CNY'
          break
        case '미국환율':
          currency = 'USD'
          break
        case '싱가포르환율':
          currency = 'SGD'
          break
        case '호주환율':
          currency = 'AUD'
          break
        case '뉴질랜드환율':
          currency = 'NZD'
          break
        case '덴마크환율':
          currency = 'DKK'
          break
        case '캐나다환율':
          currency = 'CAD'
          break
        // case '베트남환율':
        //   currency = 'VND'
        //   break
      }
      this.currencyPlaceHolder(currency)
    },
    currencyPlaceHolder(currency) {
      this.$refs.f_price.placeholder = currency
      this.$refs.d_price.placeholder = currency
      this.$refs.e_price.placeholder = currency
    }
  }
}
</script>

<style scoped>
.e_price {
  color: red;
}
</style>