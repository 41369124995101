<template>
  <table>
    <tr>
      <td style="width:15%;min-width:100px;">
        <select class="normalInput" ref="country_delivery" id="select_delivery">
          <option value = "" selected>국가선택</option>
          <option value = "ALL">전체</option>
          <option value = "홍콩">홍콩</option>
          <option value = "프랑스">프랑스</option>
          <option value = "영국">영국</option>
          <option value = "스페인">스페인</option>
          <option value = "독일">독일</option>
          <option value = "이탈리아">이탈리아</option>
          <option value = "일본">일본</option>
          <option value = "중국">중국</option>
          <option value = "미국">미국</option>
          <option value = "싱가포르">싱가포르</option>
          <option value = "네덜란드">네덜란드</option>
          <option value = "호주">호주</option>
          <option value = "뉴질랜드">뉴질랜드</option>
          <!-- <option value = "베트남">베트남</option> -->
        </select>
      </td>
      <td style="width:100%;">
        <input class="normalInput" v-on:keyup.enter="search(1)" ref="tag" id="tag" placeholder="옷,장난감,앨범,인형 등 - 입력하지 않으시면 전체 상품 조회됩니다" type="text">
      </td>
      <td style="min-width:100px;">
        <Button @click="search(1)" style="width:100%;" label="배송비조회" raised size="small" />
      </td>
    </tr>
  </table>
  <span id="estimateSearchResult" :key="estimate3Key" >
    <div v-for="(group, key) in groupingOrders" :key="key" class="card" style="width:100%; margin-top: 20px;" >
      <table style="width: 100%;">
        <tr>
          <td style="margin-right: 10px; width: 50%; text-align: left;" >
          <span style="color:red"> {{groupingOrders.get(group[0]).length}}건 배송비 : <span style="font-weight: bold;">{{ totalDeliveryPrice.get(group[0]).toLocaleString() }}</span>원</span>
          </td>
        </tr>
      </table>
      <DataTable :value="groupingOrders.get(group[0])" stripedRows>
        <template #header>
        </template>
        <Column header="사진" style="max-width:60px;">
          <template #body="slotProps">
            <img :src="slotProps.data.IMG_URL" alt="GEEK9" style="width:50px;" />
          </template>
        </Column>
        <Column field="NAME" header="상품명" style="width:50%"/>
        <Column field="COUNTRY" header="구매국가" style="min-width:90px;"/>
        <Column field="URL" header="링크" style="min-width:60px;">
          <template #body="slotProps">
            <a :href="(slotProps.data.URL).indexOf('http') === -1 ? 'https://' + slotProps.data.URL : slotProps.data.URL" target="_blank" >클릭</a>
          </template>
        </Column>
      </DataTable>
    </div> 
    <br>
    <div style="text-align: center; margin-top: 10px;" v-if="groupingOrders.size !== 0">
      <label @click="paging('prev')" class="link">
        이전
      </label>
      <span style="margin:20px">{{page}}</span>
      <label @click="paging('next')" class="link">
        다음
      </label>
    </div>  
  </span>        
</template>

<script>

// import deliveryc from '@/components/content/delivery_content.vue'
import { ref } from 'vue'

export default {
  components: {
    // deliveryc
  },
  setup() {
    let orders = ref([])
    let groupingOrders = new Map()
    let totalDeliveryPrice = new Map()

    return {
      orders, groupingOrders, totalDeliveryPrice
    } 
  },  
  data() { 
    return {
      page: 1,
      estimate3Key: 0,
      deliverycKey:-1
    }; 
  },
  methods: {
    async search(page) {
      var country = this.$refs.country_delivery.value; 
      var tag = this.$refs.tag.value
      if(country === '' ) {
        alert('국가를 선택해주세요.')
      } else {
        this.emitter.emit('dialog', {flag:true, title:'loading'})
        const param = {type:"SELECT_DELIVERY_INFO", country: country, tag: tag, page: page, id:''}
        await this.api.axiosGql("SELECT_DELIVERY_INFO", param) 
        .then(async (res) => { 
          let result = res.data
          if(result.length === 0) {
            alert('입력하신 품목이 존재하지 않습니다.')
            if(page > 1) this.page--
            return
          } else {
            this.page = page
            var orderArray = new Array()
            result.map(order => { 
              orderArray.push(JSON.stringify(order))
            })
            let ordersJson = JSON.parse(JSON.stringify(orderArray))
            this.orders = Object.keys(ordersJson).map( (key) => {
              let order = JSON.parse(ordersJson[key])
              this.date = order.range
              return order
            })
            this.groupByUid()
            
            this.estimate3Key++
          }
        })
        .catch((error) => { 
          alert(error);
          console.log(error);
        }) 
        .finally(() => { 
          this.api.axiosGql('INSERT_STAT_KEYWORD', {type:'INSERT_STAT_KEYWORD', searchType:'1', keyword:tag, opt:country, mail:this.api.getCookies('mail')})
          this.emitter.emit('dialog', {flag:false, title:'loading'})
        })
      }
    },
    async refresh() {
      this.orders = ref([])
      this.groupingOrders.clear()
      this.estimate3Key++
      this.deliverycKey++
      this.page = 1
    },
    async groupByUid() {
      this.groupingOrders.clear()
      this.orders.map( (order) => {
        let uid = order.UID
        if(this.groupingOrders.has(uid)) {
          let valueList = this.groupingOrders.get(uid)
          let deliveryPrice2 = this.totalDeliveryPrice.get(uid)
          deliveryPrice2 += parseInt(order.DELIVERY_PRICE2)
          this.totalDeliveryPrice.set(uid, deliveryPrice2)
          valueList.push(order)
          this.groupingOrders.set(uid, valueList)
        } else {
          this.totalDeliveryPrice.set(uid, order.DELIVERY_PRICE2)
          this.groupingOrders.set(uid, [order])
        }
      })
      this.deliverycKey++
    },
    async setOrder(orders) {
      this.orders = orders
      await this.groupByUid()
      this.estimate3Key++
      this.page = 1
    },
    async paging(type) {
      if(this.groupingOrders.size === 0) {
        alert('먼저 상품을 조회해주세요.')
        return
      } 
      if(type === 'prev') {
        if(this.page === 1) {
          alert('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1
      this.search(this.page)
      const el = document.getElementById('select_delivery')
      el.scrollIntoView()
    }    
  }
}
</script>

<style scoped>
td {
  padding: 0px;
}
#tag {
  width: 100%;
}
#select_delivery {
  width: 100%;
  min-width: 100px;
  text-align: center;
}
input {
  width: auto;
}
</style>
